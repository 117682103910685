<template>
  <b-container
    class="bg-white"
  >
    <b-row v-if="!isPageEmbedded">
      <b-col>
        <h1 class="mt-4">
          Aira Chat Report Generator
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="errorMessage">
          <b-alert
            show
            variant="danger"
          >
            {{ errorMessage }}
          </b-alert>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!isPageEmbedded">
      <b-col>
        <b-form
          inline
          class="inline-form mb-4"
        >
          <b-form-input
            id="inline-form-input-chatId"
            v-model="chatId"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Chat ID"
            @keydown.enter.prevent="loadChatSession()"
          />
          <b-button
            variant="primary"
            @click="loadChatSession()"
          >
            Search
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row
      v-if="chatSession"
      class="text-left"
    >
      <b-col>
        <div v-if="chatSession.flagged">
          <b-alert
            show
            variant="danger"
          >
            This chat has been flagged as inappropriate.
            <b-btn
              v-if="isLegacyChat(chatSession.id)"
              class="remove-flag"
              variant="link"
              @click="removeFlag"
            >
              Remove flag
            </b-btn>
          </b-alert>
        </div>
        <h3 v-if="!isPageEmbedded">
          Basic Information
        </h3>
        <ul
          v-if="!isPageEmbedded"
          class="ml-4"
        >
          <li>
            <b>Chat ID:</b> {{ chatSession.id }}
          </li>
          <li v-if="chatSession.title">
            <b>Chat title:</b> {{ chatSession.title }}
          </li>
          <li>
            <b>Explorer ID:</b> <a
              :href="mgmtUserLink(chatSession.userId)"
              :aria-label="`Explorer ID ${chatSession.userId}, opens a new window`"
              target="_blank"
              rel="noopener"
            >{{ chatSession.userId }}<i
              class="material-icons"
              aria-hidden="true"
            >open_in_new</i></a>
          </li>
          <li v-if="user">
            <b>Explorer name:</b> {{ user.firstName }} {{ user.lastName }}
          </li>
          <li v-if="chatSession.assistantInfo">
            <b>Assistant:</b> {{ chatSession.assistantInfo.name }} v{{ chatSession.assistantInfo.version }}
          </li>
          <li>
            <b>Created:</b>
            <time :datetime="chatSession.createdAt">
              {{ new Date(chatSession.createdAt).toLocaleString('en-us', {timeZoneName:'short'}) }}
            </time>
          </li>
        </ul>
        <h3>Messages</h3>
        <div
          v-for="message of chatSession.messages"
          :key="message.id"
          :class="['chat-bubble-container', message.role.toLowerCase()]"
        >
          <div :class="['chat-bubble', message.role.toLowerCase()]">
            <h4>
              <span class="author">
                {{ getAuthor(message) }}
              </span>
              <time :datetime="message.createdAt">
                {{ new Date(message.createdAt).toLocaleTimeString('en-us') }}
              </time>
            </h4>
            <div
              v-if="message.userFeedback"
              :aria-label="'Explorer marked as ' + (isRatedHelpful(message) ? 'helpful' : 'not helpful')"
              :class="['user-feedback', { helpful: isRatedHelpful(message) }]"
            >
              <i
                class="material-icons"
                aria-hidden="true"
              >
                {{ isRatedHelpful(message) ? 'thumb_up' : 'thumb_down' }}
              </i>
            </div>
            <a
              v-if="message.imageUrl"
              :href="message.imageUrl"
              target="_blank"
            >
              <img
                :src="message.imageUrl"
                alt="Image from Explorer, opens a new window"
              >
            </a>
            <!-- eslint-disable vue/no-v-html --->
            <div
              v-if="message.message"
              class="content"
              v-html="md.render(message.message)"
            />
            <!-- eslint-enable -->
            <div
              v-if="isReviewed(message)"
              :class="['agent-feedback', { 'verified': isVerified(message) }]"
            >
              <h5>
                <span class="author">
                  Agent {{ message.agentFeedback.authorFirstName }}
                </span>
                <time :datetime="message.agentFeedback.updatedAt">
                  {{ new Date(message.agentFeedback.updatedAt).toLocaleTimeString('en-us') }}
                </time>
                <a
                  v-if="shouldShowVerifiedContent(message)"
                  href="#"
                  @click.prevent="setShowVerifiedContentDiff(message, !shouldShowVerifiedContentDiff(message))"
                >{{ shouldShowVerifiedContentDiff(message) ? 'Hide' : 'Show' }} diff</a>
              </h5>
              <div v-if="isVerified(message)">
                <i
                  class="material-icons verified"
                  aria-hidden="true"
                >
                  verified
                </i> Verified
              </div>
              <div v-else>
                <div>
                  <div
                    v-for="failureMode of getFailureModes(message)"
                    :key="failureMode.failureMode"
                    class="failure-mode"
                  >
                    <i
                      class="material-icons failure-mode"
                      aria-hidden="true"
                    >
                      report
                    </i> {{ failureMode.name }}
                  </div>
                </div>
                <div v-if="shouldShowVerifiedContent(message)">
                  <div
                    v-if="shouldShowVerifiedContentDiff(message)"
                    class="verified-content-diff"
                  >
                    <span
                      v-for="(part, index) of getVerifiedContentDiff(message)"
                      :key="index"
                      :class="[{ 'added': part.added, 'removed': part.removed }]"
                    >{{ part.value }}</span>
                  </div>
                  <div
                    v-else
                    class="content"
                    v-html="md.render(message.agentFeedback.verifiedContent)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { diffWords } from 'diff';
import axios from 'axios';
import AiraError from '@/utils/AiraError';
import { mapGetters } from 'vuex';
import markdownit from 'markdown-it';
import API from '../../api';

export default {
  name: 'ChatReport',
  data() {
    return {
      chatId: null,
      chatSession: null,
      showVerifiedContentDiffs: [],
      user: null,
      errorMessage: null,
      failureModes: null,
      md: markdownit(),
    };
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ]),
    isPageEmbedded() {
      // If an ID token is passed as a query parameter, this page is being embedded in Dash, and we can hide some
      // sections of the report.
      return !!this.$route.query.authorization;
    },
  },
  mounted() {
    this.chatId = this.$route.query.chatId;
    if (this.chatId) {
      this.$nextTick(() => this.loadChatSession());
    }
  },
  methods: {
    async loadChatSession() {
      // Update the URL in case somebody wants to copy it.
      if (this.$route.query.chatId !== this.chatId) {
        await this.$router.replace({ query: { ...this.$route.query, chatId: this.chatId } });
      }

      if (!this.chatId) {
        return;
      }

      this.chatSession = null;
      this.showVerifiedContentDiffs = [];
      this.user = null;
      this.errorMessage = null;

      try {
        if (!this.failureModes && this.isLoggedIn) {
          this.failureModes = (await API.getChatMessageFailureModes()).payload;
        }

        const chatSession = this.isLegacyChat(this.chatId)
          ? await API.getChatSession(this.chatId)
          : await this.getRoom(this.chatId);
        if (this.isLoggedIn) {
          this.user = await API.getUserProfile(chatSession.userId);
        }

        // HACK: If an Agent provides feedback for a message but that message doesn't have a task ID, Platform doesn't
        // keep track of when the feedback was provided. Until that's fixed, assume that the message's `updatedAt` value
        // can be used as a proxy.
        chatSession.messages.forEach((message) => {
          if (message.agentFeedback && !message.agentFeedback.updatedAt) {
            message.agentFeedback.updatedAt = message.updatedAt; // eslint-disable-line no-param-reassign
          }
        });

        this.chatSession = chatSession;
      } catch (error) {
        console.log('failed to load chat', this.chatId, error);
        this.errorMessage = new AiraError(error).errorMessage;
      }
    },
    async removeFlag() {
      try {
        await API.flagChatSession(this.chatSession.id, false);
        this.chatSession.flagged = false;
      } catch (error) {
        this.errorMessage = new AiraError(error).errorMessage;
      }
    },
    mgmtUserLink(userId) {
      return `${process.env.VUE_APP_MGMT_URL}/users/${userId}`;
    },
    getAuthor(message) {
      if (message.authorName) {
        return message.authorName;
      }

      // Legacy support.
      if (message.role === 'ASSISTANT') {
        return 'Access AI';
      } else if (this.user) {
        return this.user.firstName;
      }
      return 'Explorer';
    },
    isRatedHelpful(message) {
      return message.userFeedback && message.userFeedback.rating === 1;
    },
    isReviewed(message) {
      return message.agentFeedback && message.agentFeedback.authorId;
    },
    isVerified(message) {
      if (!message.agentFeedback) {
        return false;
      }

      const { textRating, imageRating } = message.agentFeedback;
      if (textRating == null && imageRating == null) {
        return false;
      }

      // If either the text or image rating is -1, something is wrong with the message.
      return !(textRating === -1 || imageRating === -1);
    },
    getFailureModes(message) {
      if (message.agentFeedback && message.agentFeedback.analysis && message.agentFeedback.analysis.failureModes) {
        return message.agentFeedback.analysis.failureModes.map((failureMode) => this.failureModes.find((fm) => fm.failureMode === failureMode));
      }
      return [];
    },
    shouldShowVerifiedContent(message) {
      return message.agentFeedback.verifiedContent && message.agentFeedback.verifiedContent.trim() !== message.message.trim();
    },
    shouldShowVerifiedContentDiff(message) {
      return this.showVerifiedContentDiffs.includes(message.id);
    },
    getVerifiedContentDiff(message) {
      return diffWords(message.message, message.agentFeedback.verifiedContent);
    },
    setShowVerifiedContentDiff(message, show) {
      if (show) {
        this.showVerifiedContentDiffs.push(message.id);
      } else {
        this.showVerifiedContentDiffs = this.showVerifiedContentDiffs.filter((messageId) => messageId !== message.id);
      }
    },
    isLegacyChat(chatId) {
      // Legacy chat IDs are numeric.
      return chatId.match(/^\d+$/);
    },
    async getRoom(roomId) {
      const idToken = this.$route.query.authorization
        ? this.$route.query.authorization
        : await this.$store.dispatch('getFirebaseIdToken');

      let response = await axios.get(`/chat/room/${roomId}`, {
        baseURL: process.env.VUE_APP_AIRA_API_BASE_URL,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      const chatSession = {
        createdAt: response.data.createdAt,
        flagged: response.data.isFlagged,
        id: response.data.roomId,
        title: response.data.title,
        userId: response.data.ownerId,
      };

      response = await axios.get(`/chat/room/${roomId}/message`, {
        baseURL: process.env.VUE_APP_AIRA_API_BASE_URL,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      // TODO: Support userFeedback.
      chatSession.messages = response.data.messages.map((m) => ({
        authorName: m.authorRole === 'AGENT' ? `Agent ${m.authorName}` : m.authorName,
        createdAt: m.createdAt,
        id: m.messageId,
        imageUrl: m.contentType === 'IMAGE_URL' ? m.content : null,
        message: m.contentType === 'TEXT' ? m.content : null,
        role: m.authorRole,
      }));

      // Associate each Agent feedback with the verified message.
      response.data.messages.forEach((message) => {
        const agentFeedback = message.extras?.agentFeedback;
        if (Array.isArray(agentFeedback)) {
          agentFeedback.forEach((feedback) => {
            // Find the verified message.
            const target = chatSession.messages.find((m) => m.id === feedback.messageId);
            target.agentFeedback = {
              analysis: feedback.analysis,
              authorId: message.authorId,
              authorFirstName: message.authorName,
              textRating: feedback.textRating,
              updatedAt: message.createdAt,
              verifiedContent: feedback.correctedContent,
            };
          });
        }
      });

      return chatSession;
    },
  },
};
</script>

<style lang="scss">
  .material-icons {
    font-size: 16px;
    vertical-align: -2px;

    &.verified {
      font-size: 14px;
      color: green;
    }

    &.failure-mode {
      font-size: 14px;
      color: red;
    }
  }

  .remove-flag {
    font-weight: bold;
    height: 16px;
    line-height: 16px;
    padding: 0;
    vertical-align: baseline;
  }

  .chat-bubble-container {
    display: flex;
    justify-content: flex-end;

    &.user {
      justify-content: start;
    }
  }

  .chat-bubble {
    border-radius: 20px;
    display: inline-block;
    margin: 0 20px 10px 20%;
    padding: 20px;
    position: relative;

    &:after {
      border: 20px solid transparent;
      content: '';
      position: absolute;
      top: 0;
    }

    &.user {
      background-color: #eeeeee;
      margin: 0 20% 10px 20px;

      &:after {
        border-top-color: #eeeeee;
        left: -20px;
      }
    }

    &.ai, &.assistant { // assistant is the legacy role.
      background-color: #c7efff;

      &:after {
        border-top-color: #c7efff;
        right: -20px;
      }
    }

    &.agent {
      background-color: #b0e0ba;

      &:after {
        border-top-color: #b0e0ba;
        right: -20px;
      }
    }

    h4 {
      font-size: 16px;

      .author {
        font-weight: bold;
      }

      time {
        font-size: 14px;
      }
    }

    .user-feedback {
      background-color: #fde9e9;
      border: 1px solid red;
      border-radius: 50%;
      color: red;
      left: -20px;
      padding: 0 5px;
      position: absolute;
      top: 0;

      &.helpful {
        background-color: #b0e0ba;
        border-color: green;
        color: green;
      }

      .material-icons {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .agent-feedback {
      background-color: #fde9e9;
      border-radius: 5px;
      font-size: 14px;
      margin-top: 8px;
      padding: 10px;

      &.verified {
        background-color: #b0e0ba;
      }

      h5 {
        font-size: 14px;

        .author {
          font-weight: bold;
        }

        time {
          font-size: 12px;
        }

        a {
          float: right;
        }
      }

      .failure-mode:last-child {
        margin-bottom: 5px;
      }

      .verified-content-diff {
        white-space: pre-wrap;

        .added {
          color: green;
          font-weight: bold;
        }

        .removed {
          color: red;
          text-decoration: line-through;
        }
      }
    }

    img {
      border-radius: 10px;
      margin-bottom: 10px;
      max-height: 360px;
      max-width: 360px;
    }

    .content {
      p:not(:last-child) {
        margin-bottom: 1em;
      }

      h3 {
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 1em;
      }

      ol, ul {
        padding-left: 2em;
      }

      li {
        margin-bottom: 0.5em;

        p {
          margin-bottom: 0.5em;
        }
      }
    }
  }
</style>
